<template>
  <div class="bt-transaksi-setoran pa-5">
    <h6
      class="text-h5 font-weight-bold orange--text text--lighten-1 d-flex align-center"
    >
      <div
        class="rounded-pill orange lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3"
      >
        <v-icon small color="white">mdi-bell</v-icon>
      </div>
      Setoran Form
    </h6>
    <Camera class="mt-5" />
    <v-select
      solo
      label="Rembug"
      class="mb-4 mt-5"
      hide-details
      :items="opt.rembug"
      v-model="rembug"
      @change="getAnggota(rembug)"
    />
    <v-select
      solo
      label="Anggota"
      class="mb-4"
      hide-details
      :items="opt.anggota"
      v-model="anggota"
      @change="getDataSetoran(anggota)"
    />
    <v-container class="pa-0" v-show="anggota">
      <v-card class="white elevation-3 rounded-lg pa-3 align-items-end mb-3">
        <h6 class="text-h6 font-weight-bold mb-4">Presensi</h6>
        <v-row>
          <v-col cols="12">
            <v-select
              solo
              label="Presensi"
              class="mb-4"
              hide-details
              :items="opt.attendance"
              v-model="form.data.attendance"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card class="white elevation-3 rounded-lg pa-3 align-items-end mb-3">
        <h6 class="text-h6 font-weight-bold mb-4">Saldo</h6>
        <v-container class="d-flex justify-space-between pa-0">
          <div class="d-flex flex-column container pa-0">
            <h5 class="text-h5 font-weight-bold">{{ aAnggota.nama }}</h5>
            <span class="text-caption grey--text">{{ aAnggota.cif_no }}</span>
            <span class="orange--text lighten-1 font-weight-black">{{
              aAnggota.cm_name
            }}</span>
            <hr class="my-3" />
            <v-row>
              <v-col
                cols="12"
                class="text-left pt-1 pb-0 mb-0 d-flex justify-space-between"
                v-for="(tbr, tbrIndex) in form.data.taber"
                :key="`trx-${tbrIndex}`"
              >
                <v-row no-gutters>
                  <v-col cols="4">{{
                    tbr.nama_produk ? tbr.nama_produk : "Taber"
                  }}</v-col>
                  <v-col cols="4"
                    >{{ tbr.counter_angsuran ? tbr.counter_angsuran : 0 }}/{{
                      tbr.jangka_waktu
                    }}</v-col
                  >
                  <v-col cols="4" class="text-right"
                    ><b
                      >Rp {{ thousand(tbr.setoran * tbr.counter_angsuran) }}</b
                    ></v-col
                  >
                </v-row>
              </v-col>
              <v-col cols="12">
                <hr />
              </v-col>
              <v-col
                cols="12"
                class="text-left pt-1 pb-0 mb-0 d-flex justify-space-between"
                v-for="(pmb, pmbIndex) in form.data.pembiayaan"
                :key="`pmb-${pmbIndex}`"
              >
                <v-row no-gutters>
                  <v-col cols="4">{{
                    pmb.nama_produk ? pmb.nama_produk : "Pmby"
                  }}</v-col>
                  <v-col cols="4"
                    >{{ pmb.counter_angsuran }}/{{ pmb.jangka_waktu }}</v-col
                  >
                  <v-col cols="4" class="text-right"
                    ><b
                      >Rp
                      {{
                        thousand(
                          pmb.counter_angsuran * form.data.angsuran.amount
                        )
                      }}</b
                    ></v-col
                  >
                </v-row>
              </v-col>
              <v-col cols="12">
                <hr />
              </v-col>
              <v-col
                cols="12"
                class="text-left pt-1 d-flex justify-space-between"
              >
                <v-row no-gutters>
                  <v-col cols="6">Sukarela</v-col>
                  <v-col cols="6" class="text-right"
                    ><b>Rp {{ thousand(form.data.simsuk) }}</b></v-col
                  >
                </v-row>
              </v-col>
              <v-col cols="12">
                <hr />
              </v-col>
              <v-col
                cols="12"
                class="text-left pt-1 d-flex justify-space-between"
              >
                <v-row no-gutters>
                  <v-col cols="6">Simwa</v-col>
                  <v-col cols="6" class="text-right"
                    ><b>Rp {{ thousand(form.data.simwa) }}</b></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card>
      <v-card class="white elevation-3 rounded-lg pa-3 mb-3">
        <h6 class="text-h6 font-weight-bold mb-4">Setoran</h6>
        <v-row>
          <v-col cols="3">
            <label class="black--text">Angs</label>
          </v-col>
          <!-- <v-col cols="4" class="pb-0 d-flex justify-end">
            Tidak <v-switch hide-details class="pa-0 ma-0" v-model="form.data.angsuranState"/> Bayar
          </v-col> -->
          <v-col cols="4">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              v-model="form.data.frekuensi"
              :disabled="!form.data.angsuranState"
              class="text-center"
              @change="countTotalSetoran()"
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              :value="thousand(form.data.angsuran.amount * form.data.frekuensi)"
              disabled
              class="justify-end text-right"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="pb-0">
            <label class="black--text">Simwa</label>
          </v-col>
          <!-- <v-col cols="4" class="pb-0 d-flex justify-end">
            Tidak <v-switch hide-details class="pa-0 ma-0" v-model="form.data.simwaState"/> Bayar
          </v-col> -->
          <v-col cols="4">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              v-model="form.data.setoran_simpanan_wajib_freq"
              class="text-center"
              @change="countTotalSetoran()"
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              :value="thousand(form.data.setoran_simpanan_wajib_freq*form.data.setoran_simpanan_wajib)"
              @change="countTotalSetoran()"
              class="justify-end text-right"
            />
          </v-col>
          <!-- <v-col cols="4">
            <v-text-field 
              color="black"
              autocomplete="off" 
              hide-details
              solo
              dense
              v-model="form.simwaFreq"
              :disabled="!form.simwaState"
              append-outer-icon="mdi-plus"
              prepend-icon="mdi-minus"
              @click:append-outer="form.simwaFreq += 1"
              @click:prepend="(form.simwaFreq > 1) ? form.simwaFreq -= 1 : 1"
            />
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12">
            <label class="black--text">Tabungan Berencana</label>
          </v-col>
        </v-row>
        <v-row v-for="(taber, taberIndex) in form.data.taber" :key="taberIndex">
          <v-col cols="3" class="pb-0">
            <label class="black--text">{{
              taber.nama_produk ? taber.nama_produk : "Taber"
            }}</label>
          </v-col>
          <!-- <v-col cols="4" class="pb-0 d-flex justify-end">
            Tidak <v-switch hide-details class="pa-0 ma-0" v-model="taber.state"/> Bayar
          </v-col> -->
          <v-col cols="4">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              v-model="taber.freq_saving"
              :disabled="!taber.state"
              class="text-center"
              @change="countTotalSetoran()"
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              :value="thousand(taber.setoran * taber.freq_saving)"
              disabled
              class="justify-end text-right"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7" class="pb-0">
            <label class="black--text">Sukarela</label>
          </v-col>
          <v-col cols="5">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              v-model="form.data.setoran_sukarela"
              @change="countTotalSetoran()"
              class="justify-end text-right"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7" class="pb-0">
            <label class="black--text"><b>Total Setoran</b></label>
          </v-col>
          <v-col cols="5">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              disabled
              :value="
                thousand(
                  Number(form.data.total_setoran) +
                    Number(removeThousand(form.data.setoran_simpanan_wajib * form.data.setoran_simpanan_wajib_freq)) +
                    Number(removeThousand(form.data.setoran_sukarela))
                )
              "
              class="justify-end text-right"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card class="white elevation-3 rounded-lg pa-3 mb-3">
        <h6 class="text-h6 font-weight-bold mb-4">Penarikan</h6>
        <v-row>
          <v-col cols="7" class="pb-0">
            <label class="black--text">Sukarela</label>
          </v-col>
          <v-col cols="5">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              v-model="form.data.penarikan_sukarela"
              v-mask="thousandMask"
              class="justify-end text-right"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card
        class="white elevation-3 rounded-lg pa-3 mb-3"
        v-if="form.data.pokok != 0"
      >
        <h6 class="text-h6 font-weight-bold mb-4">Pencairan</h6>
        <v-row>
          <v-col cols="7" class="pb-0">
            <label class="black--text">Pokok</label>
          </v-col>
          <v-col cols="5">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              disabled
              v-model="form.data.pokok"
              v-mask="thousandMask"
              class="justify-end text-right"
            />
          </v-col>
          <v-col cols="7" class="pb-0">
            <label class="black--text">Biaya Administrasi</label>
          </v-col>
          <v-col cols="5">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              disabled
              v-model="form.data.biaya_administrasi"
              v-mask="thousandMask"
              class="justify-end text-right"
            />
          </v-col>
          <v-col cols="7" class="pb-0">
            <label class="black--text">Biaya Asuransi</label>
          </v-col>
          <v-col cols="5">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              disabled
              v-model="form.data.biaya_asuransi_jiwa"
              v-mask="thousandMask"
              class="justify-end text-right"
            />
          </v-col>
          <!-- <v-col cols="7" class="pb-0">
            <label class="black--text">Tabungan 4%</label>
          </v-col>
          <v-col cols="5">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              disabled
              v-model="form.data.tabungan_persen"
              v-mask="thousandMask"
              class="justify-end text-right"
            />
          </v-col> -->
          <v-col cols="7" class="pb-0">
            <label class="black--text">Dana Kegiatan</label>
          </v-col>
          <v-col cols="5">
            <v-text-field
              color="black"
              autocomplete="off"
              hide-details
              solo
              dense
              disabled
              v-model="form.data.dana_kebajikan"
              v-mask="thousandMask"
              class="justify-end text-right"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-btn
        block
        class="orange lighten-1 white--text mb-5"
        @click="prosesSetoran()"
      >
        Proses
      </v-btn>
    </v-container>
    <v-snackbar v-model="alert.show" :timeout="3000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>
<script>
import Camera from "@/components/Camera.vue";
import helper from "@/utils/helper";
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
export default {
  name: "SetoranForm",
  components: {
    Camera,
  },
  data() {
    return {
      form: {
        data: {
          kode_cabang: null,
          kode_rembug: null,
          kode_petugas: null,
          kode_kas_petugas: null,
          trx_date: null,
          no_anggota: null,
          no_rekening: null,
          angsuran: {
            amount: 0,
            detail: [],
          },
          frekuensi: 1,
          setoran_sukarela: 0,
          setoran_simpanan_wajib_freq: 1,
          setoran_simpanan_wajib: 0,
          penarikan_sukarela: 0,
          no_rekening_tabungan: [],
          amount_tabungan: [],
          pokok: 0,
          biaya_administrasi: 0,
          biaya_asuransi_jiwa: 0,
          tabungan_persen: 0,
          dana_kebajikan: 0,
          pembiayaan: [],
          berencana: [],
          total_setoran: 0,
          taber: [],
          attendance: 'H'
        },
      },
      alert: {
        show: false,
        msg: "",
      },
      deposit: null,
      opt: {
        rembug: [],
        anggota: [],
        attendance: [
          {
            text: 'Hadir',
            value: 'H'
          },
          {
            text: 'Izin',
            value: 'I'
          },
          {
            text: 'Sakit',
            value: 'S'
          },
          {
            text: 'Alpha',
            value: 'A'
          }
        ]
      },
      rembug: null,
      anggota: null,
      trx_date: null,
      aAnggota: {
        nama: null,
        cif_no: null,
        cm_name: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...helper,
    async getDataSetoran(cif_no) {
      let cm_code = this.$route.params.cm_code;
      if (!cif_no) {
        cif_no = this.$route.params.cif_no;
      } else {
        this.$router.push(`/transaksi/setoran-form/${cm_code}/${cif_no}`);
      }
      if (cif_no) {
        let payload = new FormData();
        payload.append("cif_no", cif_no);
        this.anggota = Number(cif_no);
        try {
          let req = await services.transSetoranDeposit(
            payload,
            this.user.token
          );
          if (req.status === 200) {
            let dataDeposit = { ...req.data.data };

            let formData = {
              kode_cabang: this.user.branch_code,
              kode_rembug: this.rembug,
              kode_petugas: this.user.fa_code,
              kode_kas_petugas: this.user.account_cash_code,
              trx_date: this.trx_date ? this.trx_date : this.getDate(),
              no_anggota: this.anggota,
              no_rekening: dataDeposit.no_rekening,
              angsuran: dataDeposit.angsuran,
              frekuensi: 1,
              setoran_sukarela: 0,
              setoran_simpanan_wajib: dataDeposit.simwa,
              setoran_simpanan_wajib_freq: 1,
              penarikan_sukarela: 0,
              simwaState: true,
              angsuranState: true,
              taber: [],
              pokok: dataDeposit.pokok,
              biaya_administrasi: dataDeposit.biaya_administrasi,
              biaya_asuransi_jiwa: dataDeposit.biaya_asuransi_jiwa,
              tabungan_persen: dataDeposit.tabungan_persen,
              dana_kebajikan: dataDeposit.dana_kebajikan,
              pembiayaan: dataDeposit.pembiayaan,
              total_setoran: 0,
              simsuk: dataDeposit.simsuk,
              attendance: 'H'
            };
            dataDeposit.berencana.forEach((taber, index) => {
              let dataTaber = { ...taber };
              dataTaber.state = true;
              formData.taber.push(dataTaber);
            });
            this.form.data = { ...formData };
            this.countTotalSetoran();
          } else {
            this.alert = {
              show: true,
              msg: data.message,
            };
          }
          this.opt.anggota.map((anggota, index) => {
            if (anggota.value === this.$route.params.no_anggota) {
              this.aAnggota = anggota.data;
            }
          });
        } catch (error) {
          this.alert = {
            show: true,
            msg: `Error on get setoran ${error}`,
          };
        }
      }
    },
    async getRembug() {
      let day = new Date().getDay();
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("day", day);
      // payload.append("day", 1);
      this.opt.rembug = [];
      this.loading = true;
      try {
        let req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          req.data.data.map((item) => {
            this.opt.rembug.push({
              text: item.cm_name,
              value: Number(item.cm_code),
            });
          });
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
        this.loading = false;
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
        this.loading = false;
      }
    },
    async getAnggota(cm_code) {
      if (!cm_code) {
        cm_code = this.$route.params.cm_code;
      } else {
        this.$router.push(`/transaksi/setoran-form/${cm_code}`);
      }
      let payload = new FormData();
      payload.append("cm_code", cm_code);
      this.rembug = Number(cm_code);
      this.opt.anggota = [];
      try {
        let req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          req.data.data.map((item) => {
            this.opt.anggota.push({
              value: Number(item.cif_no),
              text: item.nama,
              data: item,
            });
          });
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async prosesSetoran() {
      let payload = new FormData();
      let formData = { ...this.form.data };
      formData.total_angsuran = formData.total_angsuran
        ? Number(formData.total_angsuran.replace(/\./g, ""))
        : 0;
      formData.setoran_sukarela = formData.setoran_sukarela
        ? Number(formData.setoran_sukarela.replace(/\./g, ""))
        : 0;
      formData.setoran_simpanan_wajib = formData.setoran_simpanan_wajib
        ? Number(formData.setoran_simpanan_wajib.replace(/\./g, ""))
        : 0;
      formData.penarikan_sukarela = formData.penarikan_sukarela
        ? Number(formData.penarikan_sukarela.replace(/\./g, ""))
        : 0;
      formData.pokok = formData.pokok
        ? Number(this.removeThousand(formData.pokok))
        : 0;
      formData.biaya_administrasi = formData.biaya_administrasi
        ? Number(this.removeThousand(formData.biaya_administrasi))
        : 0;
      formData.biaya_asuransi_jiwa = formData.biaya_asuransi_jiwa
        ? Number(this.removeThousand(formData.biaya_asuransi_jiwa))
        : 0;
      formData.tabungan_persen = formData.tabungan_persen
        ? Number(this.removeThousand(formData.tabungan_persen))
        : 0;
      formData.dana_kebajikan = formData.dana_kebajikan
        ? Number(this.removeThousand(formData.dana_kebajikan))
        : 0;
      payload.append("branch_id", formData.kode_cabang);
      payload.append("cm_code", formData.kode_rembug);
      payload.append("fa_code", formData.kode_petugas);
      payload.append("account_cash_code", formData.kode_kas_petugas);
      payload.append("transaction_date", formData.trx_date);
      payload.append("cif_no", formData.no_anggota);
      payload.append("account_financing_no", formData.no_rekening);
      payload.append("frekuensi", formData.frekuensi);
      // formData.angsuran.detail.map((item,i) => {
      //   payload.append(`angsuran[${i}][id]`,item.id)
      //   payload.append(`angsuran[${i}][nama]`,item.nama)
      //   payload.append(`angsuran[${i}][amount]`,item.amount*formData.frekuensi)
      // })
      payload.append("setoran_sukarela", formData.setoran_sukarela);
      payload.append("setoran_simpanan_wajib", formData.setoran_simpanan_wajib * formData.setoran_simpanan_wajib_freq);
      payload.append("penarikan_sukarela", formData.penarikan_sukarela);
      formData.taber.map((item) => {
        if (item.no_rekening) {
          item.setoran = item.setoran
            ? Number(item.setoran.replace(/\./g, ""))
            : 0;
          payload.append("account_saving_no[]", item.no_rekening);
          payload.append("freq_saving[]", item.freq_saving);
          payload.append("rencana_setoran[]", item.setoran);
        }
      });

      // payload.append('pokok',Number(formData.pokok))
      // payload.append('biaya_administrasi',Number(formData.biaya_administrasi))
      // payload.append('biaya_asuransi_jiwa',Number(formData.biaya_asuransi_jiwa))
      // payload.append('tabungan_persen',Number(formData.tabungan_persen))
      // payload.append('dana_kebajikan',Number(formData.dana_kebajikan))
      payload.append('attendance',formData.attendance)
      try {
        if(formData.simsuk >= formData.penarikan_sukarela) {
          let req = await services.transSetoranDepositProses(
            payload,
            this.user.token
          );
          if (req.status === 200) {
            this.alert = {
              show: true,
              msg: req.data.msg,
            };
            setTimeout(() => {
              this.$router.push("/anggota/" + this.rembug).catch(() => {});
            }, 500);
          } else {
            this.alert = {
              show: true,
              msg: data.message,
            };
          }
        } else {
          this.alert = {
              show: true,
              msg: 'Penarikan Sukarela tidak bisa melebihi saldo sukarela',
            };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: `Error on get anggota ${error}`,
        };
      }
    },
    countTotalSetoran() {
      let { angsuran, frekuensi, taber } = this.form.data;
      let total_taber = 0;
      taber.map((item) => {
        total_taber =
          total_taber + Number(item.setoran) * Number(item.freq_saving);
      });
      let total_setoran = (Number(angsuran.amount) * Number(frekuensi)) + Number(total_taber);
      this.form.data.total_setoran = total_setoran;
    },
    getDate() {
      let today = new Date();
      let day = today.getDate();
      let month = today.getMonth()+1;
      month = month.toString()
      let year = today.getFullYear();
      return `${day}/${month.padStart(2, '0')}/${year}`;
    },
    setForm() {
      let kode_rembug = this.$route.params.kode_rembug;
      let no_anggota = this.$route.params.no_anggota;
      let date = this.$route.params.date;
      this.trx_date = date ? date : null;
      this.rembug = kode_rembug;
      this.anggota = no_anggota ? no_anggota : null;
    },
  },
  mounted() {
    this.getDataSetoran();
    this.getRembug();
    this.getAnggota();
    // this.setForm()
  },
};
</script>
